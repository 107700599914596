import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  // scrollBehavior() {
  // return { x: 0, y: 0 };
  // },
  routes: [
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "Home" */ "./views/Home.vue"),
    },
    {
      path: "/reel",
      name: "reel",
      component: () =>
        import(/* webpackChunkName: "Reel" */ "./views/Reel.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "About" */ "./views/About.vue"),

    },
    {
      path: "/projects",
      name: "projects",
      component: () =>
        import(/* webpackChunkName: "Category" */ "./views/Category.vue"),
    },
    {
      path: "/projects/:id",
      name: "project",
      props: true,
      component: () =>
        import(/* webpackChunkName: "Project" */ "./views/Project.vue"),
    },
    {
      path: "/privacy-policy",
      name: "privacy",
      component: () =>
        import(/* webpackChunkName: "Privacy" */ "./views/Privacy.vue"),
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});
