import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const auth = {
  username: process.env.VUE_APP_USERNAME,
  password: process.env.VUE_APP_PASSWORD,
};

export default new Vuex.Store({
  state: {
    site: [],
    home: [],
    project: [],
    projects: [],
    about: [],
    privacy: [],
  },
  getters: {
    getProjectByUID:
      (state) =>
      (uid, collection = state.projects) => {
        let i = collection.length;
        while (i--) {
          if (collection[i].uid === uid) {
            return collection[i];
          }
        }
      },
  },
  actions: {
    async loadSite({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "site",
            select: {
              title: true,
              url: true,
              categories: "page('projects').categories.split",
              animatedlogo: {
                  query: "site.logo.toFile",
                  select: {
                        url: "file.url"
                  }
              },
              about: {
                  query: "page('about')",
                  select: {
                        bgcolor: "page.bgcolor",
                        fontcolor: "page.fontcolor",
                  }
              },
              menu: {
                query: "site.children.listed",
                select: {
                  title: true,
                  uid: true,
                },
              },
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_SITE", response.data.result);
        });
    },
    async loadHome({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('home')",
            select: {
              title: true,
              url: true,
              showreel: {
                query: "page.showreel.toStructure",
                select: {
                  caption: "structureItem.caption",
                  duration: "structureItem.duration",
                  fullbleed: "structureItem.fullbleed.toBool",
                  background: "structureItem.background",
                  captioncolor: "structureItem.captioncolor",
                  file: {
                    query: "structureItem.file.toFile",
                    select: {
                      url: "file.url",
                      srcset: "file.srcset([400, 800, 1200, 1600, 2000])",
                      type: "file.type",
                      thumb: "file.resize(200).url",
                    }
                  }
                }
              },
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_HOME", response.data.result);
        });
    },
    async loadProjects({ commit }, payload) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('projects')",
            select: {
              items: {
                query: "page.children.listed",
                select: {
                  title: true,
                  uid: true,
                  category: "page.category.split",
                  size: "page.size",
                  work: {
                    query: "page.work.toLayouts",
                    select: {
                      columns: true,
                      id: true,
                    },
                  },
                  captioncolor: "page.captioncolor",
                  cover: {
                    query: "page.cover.toFiles",
                    select: {
                        type: "file.type",
                        url: "file.url",
                        srcset: "file.srcset([400, 800, 1200, 1600, 2000])",
                        ratio: true,
                        orientation: true,
                        thumb: "file.resize(200).url",
                    },
                  },
                  overview: {
                    query: "page.overview.toFile",
                    select: {
                      url: "file.resize(1500).url",
                      srcset: "file.srcset([400, 800, 1200, 1600, 2000])",
                      ratio: true,
                      orientation: true,
                      thumb: "file.resize(200).url",
                    },
                  },
                },
                pagination: {
                  page: payload,
                  limit: 100,
                },
              },
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_PROJECTS", response.data.result.items);
        });
    },
    async loadProject({ commit }, payload) {
      await axios
        .post(
          "/api/query",
          {
            query: `page('projects').children.find('${payload}')`,
            select: {
              title: true,
              uid: true,
              category: "page.category.value",
              description: "page.description.kirbytext",
              bgcolor: "page.bgcolor",
              textcolor: "page.textcolor",
              images: {
                query: "page.images",
                select: {
                  url: "file.url",
                  thumb: "file.resize(200).url",
                },
              },
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_PROJECT", response.data.result);
        });
    },
    async loadAbout({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('about')",
            select: {
              title: true,
              uid: true,
              header: "page.header.kirbytext",
              continued: "page.continued",
              address: "page.address.kirbytext",
              text: "page.text.kirbytext",
              impressum: "page.impressum.kirbytext",
              image: {
                  query: "page.teambild.toFile",
                  select: {
                        url: "file.url",
                        thumb: "file.resize(200).url",
                  }
              },
              team: {
                query: "page.team.toStructure",
                select: {
                  name: "structureItem.name",
                  job: "structureItem.job",
                  captioncolor: "structureItem.captioncolor",
                  image: {
                    query: "structureItem.image.toFile",
                    select: {
                      url: "file.resize(600).url",
                      thumb: "file.resize(200).url",
                    }
                  }
                }
              }
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_ABOUT", response.data.result);
        });
    },
    async loadPrivacy({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('privacy-policy')",
            select: {
              title: true,
              uid: true,
              text: "page.text.kirbytext",
            },
          },
          { auth }
        )
        .then(function (response) {
          commit("SET_PRIVACY", response.data.result);
        });
    },
  },
  mutations: {
    SET_SITE(state, data) {
      state.site = data;
    },
    SET_HOME(state, data) {
      state.home = data;
    },
    SET_PROJECTS(state, pages) {
      if (state.projects.pagination) {
        state.projects.pagination = pages.pagination;
        for (var i = 0; i < pages.data.length; i++) {
          state.projects.data.push(pages.data[i]);
        }
      } else {
        state.projects = pages;
      }
    },
    SET_PROJECT(state, project) {
      state.project = project;
    },
    SET_ABOUT(state, data) {
      state.about = data;
    },
    SET_PRIVACY(state, data) {
      state.privacy = data;
    },
  },
});
