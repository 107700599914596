<template>
    <div class="h-full snap-y snap-mandatory overflow-y-auto overflow-x-hidden w-full absolute top-0 left-0 transition duration-700 ease-in-out" ref="main" v-if="Object.keys(projects).length > 0">
        <div class="lg:grid lg:grid-cols-3 h-full lg:auto-rows-[calc(100vh-7rem)]">
            <router-link  :to="{ name: 'project', params: { id: project.uid }}" class="snap-center block w-full h-full relative overflow-hidden" v-for="project in preview" :key="project.uid">
                <video class="lozad w-full h-full object-cover" v-if="project.cover[0].type == 'video'" autoplay loop muted playsinline>
                    <source :data-src="project.cover[0].url" type="video/mp4">
                </video>
                <img v-else class="lozad w-full h-full object-cover scale-105" :src="project.cover[0].thumb" :data-src="project.cover[0].thumb" :data-srcset="project.cover[0].srcset" :alt="project.title">
                <div class="caption absolute bottom-0 p-2 left-0 w-full font-mono" :style="{ color: project.captioncolor ? project.captioncolor : 'black' }" v-html="project.title"></div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import lozad from "lozad";
const observer = lozad('.lozad', {
      rootMargin: "0px",
      enableAutoReload: true, // it will reload the new image when validating attributes changes
});
export default {
      computed: {
            ...mapState(["projects"]),
            preview() {
                  let projects = this.projects.data;
                  return projects;
            }
      },
      mounted() {
            observer.observe();
      }
};
</script>
