<template>
      <div id="app" class="voile" :class="$route.name" v-if="Object.keys(site).length > 0">
            <reel />
            <section  id="projects" class="w-full">
                  <router-link to="/projects" class="section-link projects" :class="{ 'hide': $route.name == 'projects' || $route.name == 'project' }">
                        <h1>Projects</h1>
                  </router-link>
                  <router-link to="/projects" :style="{ 'background-color': site.about.bgcolor, 'color': site.about.fontcolor }" class="close-btn" :class="{ 'show': $route.name == 'project' }">
                        <h1>BACK</h1>
                  </router-link>
                  <transition :name="transitionName">
                        <preview v-show="$route.name !== 'project'" :class="{ 'blur-xl': $route.name !== 'projects' && $route.name !== 'project' && $route.name !== 'home', 'hide-caption': $route.name == 'home' }" />
                  </transition>
                  <div class="section-inner">
                        <transition name="rollproject">
                              <router-view :key="$route.fullPath" v-if="$route.name == 'project'" />
                        </transition>
                  </div>
            </section>
            <section id="about" :style="{ 'background-color': site.about.bgcolor, 'color': site.about.fontcolor }" :class="{ 'vanish': $route.name == 'project' }">
                  <router-link to="/about" class="section-link" :class="{ 'ontop': $route.name !== 'home', 'hide': $route.name == 'project' }">
                        <h1 v-if="!site.animatedlogo">Studio Voile</h1>
                        <img v-else :src="site.animatedlogo.url" class="h-10" alt="">
                  </router-link>
                  <div class="section-inner py-4">
                        <router-view :key="$route.fullPath" v-if="$route.name == 'about' || $route.name == 'privacy'" />
                  </div>
            </section>
      </div>
</template>

<script>
import { mapState } from "vuex";
import Reel from "@/components/Reel.vue";
import Preview from "@/components/Preview.vue";

export default {
    name: "App",
    metaInfo() {
        return {
        titleTemplate: "Studio Voile" + " %s",
        };
    },
    data() {
        return {
                transitionName: 'fade'
        }
    },
    computed: mapState(["site"]),
    components: {
        Reel,
        Preview
    },
    watch: {
        $route (to, from) {
        if(!from.name) {
                this.transitionName = 'fade';
        } else {
                this.transitionName = 'rollprojects';
        }
        if(from.name && to.name == 'home') {
            this.$root.$emit('show-reel');
        }
        }
    },
    created() {
        this.$store.dispatch("loadSite");
        this.$store.dispatch("loadHome");
        this.$store.dispatch("loadAbout");
        this.$store.dispatch("loadProjects", 0);
        this.$store.dispatch("loadPrivacy");
    },
};
</script>
