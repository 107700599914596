<template>
      <section id="reel" class="bg-grey" :class="{ 'vanish': $route.name == 'project' }">
            <router-link to="/" class="section-link" :class="{ 'hide': $route.name == 'reel' || $route.name == 'home' }">
                  <h1>HOME</h1>
            </router-link>
            <div class="section-inner" :class="{ 'blur': $route.name !== 'reel' && $route.name !== 'home' }">
                  <div v-if="Object.keys(home).length > 0" class="transition-all duration-700 transform absolute top-0 bottom-0 bg-black text-white w-full h-full" ref="main">
                        <div class="md:cursor-none showreel-slides absolute top-0 bottom-0 w-full h-full">
                                    <div 
                                    v-for="(item, index) in showreel" 
                                    :key="index"
                                    ref="slide"
                                    :data-duration="item.duration.length > 0 ? item.duration : 3"
                                    :data-video="item.file.type == 'video'"
                                    class="showreel-slide"
                                    :class="{ 'prev': checkPrev(index), 'next': checkNext(index), 'animation': animation && current == index, 'current': current == index }"
                                    >
                                          <div v-if="item.file && item.file.type == 'image'" class="absolute w-full h-full">
                                                <img :src="item.file.thumb" :data-src="item.file.url" :data-srcset="item.file.srcset"  :alt="item.caption" class="lozad w-full h-full object-cover" />
                                          </div>
                                          <div v-if="item.file && item.file.type == 'video'" class="absolute w-full h-full">
                                                <video playsinline muted :data-src="item.file.url" :alt="item.caption" class="lozad w-full h-full object-cover" />
                                          </div>
                                          <div class="absolute bottom-0 left-0 p-2 text w-full font-mono" :style="{ 'color': item.captioncolor ? item.captioncolor : 'black' }" v-html="item.caption"></div>
                                    </div>
                        </div>
                        <div class="cursor-pointer absolute top-0 right-0 w-full h-full select-none" @touchstart="touchstart($event)" @touchmove="touchmove($event)" @touchend="touchend($event)" @click="next()"></div>
                  </div> 
            </div>
      </section>
</template>

<script>
import { mapState } from "vuex";
import lozad from "lozad";
const observer = lozad('.lozad', {
                  rootMargin: "0px 0px",
                  threshold: 0.1, // ratio of element convergence
                  enableAutoReload: true, // it will reload the new image when validating attributes changes
            });
export default {
      data() {
            return {
                  startY: 0,
                  touchY: 0,
                  current: 0,
                  animation: false,
                  interval: null,
                  duration: 5000,
                  time: 0,
                  progress: 0,
            }
      },
      computed: {
            ...mapState(["site", "home"]),
            showreel() {
                  return this.home.showreel
            },
            total() {
                  return this.home.showreel.length
            }
      },
      mounted() {
            this.startReel();
            observer.observe();
      },
      beforeDestroy() {
            clearInterval(this.interval)
      },
      methods: {
            touchstart(e) {
                  this.startY = e.touches[0].clientY;
            },
            touchmove(e) {
                  this.touchY = e.touches[0].clientY;
            },
            touchend() {
                  let diff = this.startY - this.touchY;
                  if(diff > 0) {
                        this.next();
                  } 
                  this.startY = 0;
                  this.touchY = 0;
            },
            checkPrev(index) {
                  if(this.current == 0) {
                        return index == this.total - 1;
                  } else {
                        return index == this.current - 1;
                  }
            },
            checkNext(index) {
                  if(this.current == this.total - 1) {
                        return index == 0;
                  } else {
                        return index == this.current + 1;
                  }
            },
            startReel() {
                  clearInterval(this.interval)
                  this.interval = setInterval(this.timer, 10);
            },
            goTo(index) {
                  this.time = 0;
                  this.progress = 0;
                  this.current = index;
                  let cur = this.$refs.slide[this.current];
                  if(cur.dataset.video) {
                  let video = cur.querySelector('video');
                  video.currentTime = 0;
                  }
            },
            timer() {
                  this.time += 10;
                  let cur = this.$refs.slide[this.current];
                  let duration = parseInt(cur.dataset.duration) * 1000;
                  observer.observe();
                  if(cur.hasAttribute('data-video')) {
                        let video = cur.querySelector('video');
                        if(video.ended) {
                              video.currentTime = 0;
                              this.time = 0;
                              this.progress = 0;
                              this.next();
                        }
                        else if(video.currentTime == 0) {
                              video.play();
                        }
                        else {
                              // console.log(, duration);
                              if(video.currentTime * 1000 >= duration) {
                                    video.pause();
                                    video.currentTime = 0;
                                    this.time = 0;
                                    this.progress = 0;
                                    this.next();
                              } else {
                                    this.progress = video.currentTime / video.duration * 100;
                              }
                             
                        }
                  } else {
                        if(this.time >= duration) {
                              this.time = 0;
                              this.progress = 0;
                              this.next();
                        } else {
                              this.progress = this.time / duration * 100;
                        }
                  }
            },
            next() {
                  if(!this.animation) {
                        this.animation = true;
                        if(this.current == this.total - 1) {
                              this.goTo(0);
                        } else {
                              this.goTo(this.current + 1);
                        }
                        setTimeout(() => {
                              this.animation = false;
                        }, 1000)
                  }
            },

      }
}
</script>